import React from 'react';
import styled from 'styled-components';

const Main = styled.div`
  display: flex;
  width: 100%;
  flex-direction: column;
`;
const Label = styled.label`
  display: flex;
  font-weight: 400;
  display: inline-block;
  margin-bottom: 0.5rem;
`;
const InputElement = styled.input`
  box-sizing: border-box;
  width: 100%;
  height: auto;
  padding: 0.4375rem 0.75rem;
  font-size: 0.8125rem;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  border: 1px solid #e1e5eb;
  font-weight: 300;
  will-change: border-color, box-shadow;
  border-radius: 0.25rem;
  box-shadow: none;

  transition: box-shadow 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06),
    border 0.25s cubic-bezier(0.27, 0.01, 0.38, 1.06);
  :hover {
    border-color: #b3bdcc;
  }
  :focus {
    border-color: #007bff;
    color: #495057;
    background-color: #fff;
    box-shadow: 0 0.313rem 0.719rem rgb(0 123 255 / 10%),
      0 0.156rem 0.125rem rgb(0 0 0 / 6%);
    outline: none;
  }
  ::placeholder {
    color: #868e96;
  }
`;
function Input({
  label,
  value,
  placeholder,
  type = 'text',
  id,
  onChange,
  disabled,
}) {
  return (
    <Main>
      {label && <Label>{label}</Label>}
      <InputElement
        disabled={disabled}
        name={id}
        value={value}
        placeholder={placeholder}
        type={type}
        autocomplete="false"
        onChange={onChange}
      />
    </Main>
  );
}

export default Input;
