import React, { useContext } from "react";
import { MonitorContext } from "../contexts/MonitorContext";

import Panel from "../ui/Panel";
import Row from "../ui/Row";
import Table from "../ui/Table";
import Column from "../ui/Column";
import moment from "moment";
import { useNavigate } from "react-router-dom";

function Engines({ engines }) {
  const { clients } = useContext(MonitorContext);
  const history = useNavigate();
  return (
    <Row>
      <Column>
        <Row>
          <Panel title={"Engines list"}>
            <Column>
              Total: {clients?.filter((c) => c.app === "engine")?.length}
              {clients && (
                <Table
                  data={clients
                    ?.filter((c) => c.app === "engine")
                    ?.sort((a, b) => {
                      return a.machine_name?.localeCompare(b.machine_name);
                    })}
                  columns={[
                    { Header: "ID", accessor: "id" },
                    { Header: "IP", accessor: "ip" },
                    { Header: "Machine", accessor: "machine_name" },
                    {
                      Header: "Engine",
                      accessor: ({ engine }) => {
                        return engines?.find((e) => {
                          return e.key === engine;
                        })?.name;
                      },
                    },
                    {
                      Header: "Query params",
                      style: {
                        overflow: "hidden",
                        maxWidth: "200px",
                        textWrap: "nowrap",
                      },
                      accessor: ({ query_params }) => {
                        return typeof query_params === "string"
                          ? query_params
                          : "";
                      },
                    },
                    {
                      Header: "Output type",
                      accessor: "type",
                    },
                    { Header: "Project", accessor: "project_name" },
                    {
                      Header: "Project Last Updated",
                      accessor: (data) => {
                        return data.project_last_updated
                          ? moment(data.project_last_updated).format(
                              "DD-MM-YYYY hh:mm.ss"
                            )
                          : "";
                      },
                    },
                    {
                      Header: "Last contact",
                      accessor: (data) => {
                        return moment(data.time).fromNow();
                      },
                    },
                  ]}
                  onRowSelect={(item) => {
                    history.push("/client/" + item._id);
                  }}
                />
              )}
            </Column>
          </Panel>
        </Row>
      </Column>
    </Row>
  );
}

export default Engines;
