import React from "react";
import styled from "styled-components";
import { NavLink } from "react-router-dom";

const LI = styled.li`
  display: flex;
  font-weight: 400;
  width: 100%;
  cursor: pointer;
`;

const LINK = styled(NavLink)`
  text-decoration: none;
  padding: 0.9375rem 1.5625rem;
  width: 100%;
  color: #3d5170;
  transition: box-shadow 0.2s ease, color 0.2s ease, background-color 0.2s ease;
  transition-property: box-shadow, color, background-color;
  transition-duration: 0.2s, 0.2s, 0.2s;
  transition-timing-function: ease, ease, ease;
  transition-delay: 0s, 0s, 0s;

  :active {
    background-color: inherit;
  }

  :hover {
    box-shadow: inset 0.1875rem 0 0 #007bff;
    background-color: #fbfbfb;
    color: #007bff;
  }
`;
function NavigationItem({ title, url }) {
  return (
    <LI>
      <LINK
        to={`/${url?.toLowerCase()}`}
        style={({ isActive }) => {
          return isActive
            ? {
                boxShadow: "inset 0.1875rem 0 0 #007bff",
                backgroundColor: "#fbfbfb",
                color: "#007bff",
              }
            : {};
        }}
      >
        {title}
      </LINK>
    </LI>
  );
}

export default NavigationItem;
