import React from 'react';
import styled from 'styled-components';
const Main = styled.div`
  display: flex;
  width: 100%;
  padding-top: 1em;
  padding-bottom: 1em;
  flex-wrap: wrap;
  align-items: center;
  border-bottom: ${(props) => (props.bottom_border ? '1px solid #d9e9f9' : '')};
`;
function Row({ children, bottom_border, style }) {
  return (
    <Main style={style} bottom_border={bottom_border}>
      {children}
    </Main>
  );
}

export default Row;
