import "./App.css";
import { useContext, useEffect } from "react";
import { MonitorContext } from "./contexts/MonitorContext.js";
import styled from "styled-components";
import Header from "./header/Header.js";
import {
  ApolloClient,
  InMemoryCache,
  ApolloProvider,
  createHttpLink,
  gql,
  useQuery,
} from "@apollo/client";

import { setContext } from "@apollo/client/link/context";
import { UserContext, UserProvider } from "./contexts/UserContext.js";
import Login from "./Authentication/Login.js";
import Navigation from "./navigation/Navigation.js";
import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Football from "./Clients/Football.js";
import Engines from "./Clients/Engines.js";
const authLink = setContext((_, { headers }) => {
  // get the authentication token from local storage if it exists
  const token = localStorage.getItem("token");
  // return the headers to the context so httpLink can read them
  return {
    headers: {
      ...headers,
      authorization: token ? `${token}` : "",
    },
  };
});

const httpLink = createHttpLink({
  uri:
    (window.ENV?.REACT_APP_PROJECT_SERVER ||
      process.env.REACT_APP_PROJECT_SERVER) + "/graphql",
});

const client = new ApolloClient({
  cache: new InMemoryCache(),
  link: authLink.concat(httpLink),
});

const LOGGED_IN = gql`
  query LoggedIn {
    me {
      username
      token
      features {
        name
      }
    }
  }
`;
const GET_ENGINES = gql`
  query GetEngines {
    enginesForUser {
      name
      _id
      key
    }
  }
`;
const Main = styled.div`
  display: flex;
  width: 100%;
`;
const Content = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 315px;
  width: 100%;
`;
function App() {
  const { clients } = useContext(MonitorContext);
  return (
    <Main>
      <ApolloProvider client={client}>
        <Router basename={window.ENV?.PUBLIC_URL || process.env.PUBLIC_URL}>
          <UserProvider>
            <RoutesMain />
          </UserProvider>
        </Router>
      </ApolloProvider>
    </Main>
  );
}

function RoutesMain() {
  const { setUserToken } = useContext(UserContext);
  const {
    loading,
    error,
    data,
    refetch: refetchLogin,
  } = useQuery(LOGGED_IN, {
    fetchPolicy: "network-first",
    client: client,
  });
  const { data: engines } = useQuery(GET_ENGINES, {
    fetchPolicy: "network-first",
    client: client,
  });

  useEffect(() => {
    if (data) {
      setUserToken(data?.me?.token);
    }
  }, [data]);

  return (
    <>
      {!data?.me?.token && (
        <Login
          loggedIn={() => {
            refetchLogin();
          }}
        />
      )}
      {data?.me?.token && (
        <Main>
          <Navigation />
          <Content>
            <Header></Header>
            <Routes>
              <Route path="/football" element={<Football />} />

              <Route
                path="/engines"
                element={<Engines engines={engines?.enginesForUser} />}
              />
            </Routes>
          </Content>
        </Main>
      )}
    </>
  );
}
export default App;
