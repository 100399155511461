import React, { useContext } from "react";
import { MonitorContext } from "../contexts/MonitorContext";

import Panel from "../ui/Panel";
import Row from "../ui/Row";
import Table from "../ui/Table";
import Column from "../ui/Column";
import moment from "moment";
import { useNavigate } from "react-router-dom";

function Football() {
  const { clients } = useContext(MonitorContext);
  const history = useNavigate();
  return (
    <Row>
      <Column>
        <Row>
          <Panel title={"Football UI list"}>
            <Column>
              Total: {clients?.filter((c) => c.app === "football")?.length}
              {clients && (
                <Table
                  data={clients
                    ?.filter((c) => c.app === "football")
                    ?.sort((a, b) => {
                      return a.machine_name?.localeCompare(b.machine_name);
                    })}
                  columns={[
                    { Header: "ID", accessor: "id" },
                    { Header: "IP", accessor: "ip" },
                    { Header: "Machine", accessor: "machine_name" },
                    { Header: "Version", accessor: "version" },
                    { Header: "Screen", accessor: "page" },
                    { Header: "Game", accessor: "game" },
                    {
                      Header: "Home Lineups",
                      accessor: (data) => {
                        return data.has_home_lineups ? "yes" : "no";
                      },
                    },
                    {
                      Header: "Away Lineups",
                      accessor: (data) => {
                        return data.has_away_lineups ? "yes" : "no";
                      },
                    },
                    {
                      Header: "Last contact",
                      accessor: (data) => {
                        return moment(data.time).fromNow();
                      },
                    },
                  ]}
                  onRowSelect={(item) => {
                    history.push("/client/" + item._id);
                  }}
                />
              )}
            </Column>
          </Panel>
        </Row>
      </Column>
    </Row>
  );
}

export default Football;
