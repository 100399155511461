import React from 'react';
import styled from 'styled-components';
const Main = styled.div`
  display: flex;
  flex-direction: column;
  padding-left: 15px;
  padding-right: 15px;
  width: 100%;
  box-sizing: border-box;
`;
function Column({ children, size = 12 }) {
  return <Main style={{ width: (size / 12) * 100 + '%' }}>{children}</Main>;
}

export default Column;
